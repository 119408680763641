import { createStyles } from '@mantine/core';

export const useTableStyles = createStyles((theme) => ({
  table: {
    borderCollapse: 'collapse'
  },

  tableHeader: {
    '> tr': {
      '> th': {
        padding: '4px 12px',
        borderWidth: 0,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2],
        borderStyle: 'solid',
        borderBottomWidth: '1px'
      },

      '> th:last-child': {
        paddingRight: '18px'
      }
    }
  },

  tableBody: {
    '> tr': {
      '> td': {
        padding: '8px 12px 6px 12px',

        borderWidth: 0,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2],
        borderStyle: 'solid',
        borderBottomWidth: '1px'
      },

      '> td:last-child': {
        paddingRight: '18px'
      }
    },

    '> tr:hover > td': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
    },

    '> tr:last-child': {
      '> td': {
        borderBottomWidth: 0
      }
    }
  }
}));
