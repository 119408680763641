import {
  ActionIcon,
  AppShell,
  Avatar,
  Button,
  Divider,
  Group,
  Header,
  Navbar,
  ScrollArea,
  Stack,
  Text,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { AccountPage } from 'account/AccountPage';
import { AccountsSidebar } from 'account/AccountsSidebar';
import { NewAccountPage } from 'account/NewAccountPage';
import 'App.scss';
import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { MoonStars, Sun } from 'tabler-icons-react';

const App = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <AppShell
      padding="md"
      fixed
      navbar={
        <Navbar width={{ base: 300 }} p="xs">
          <Navbar.Section grow component={ScrollArea}>
            <Routes>
              <Route path="/a/*" element={<AccountsSidebar />} />
              <Route path="/account/*" element={<AccountsSidebar />} />
            </Routes>
          </Navbar.Section>
          <Navbar.Section p="sm">
            <Divider mb="md" />
            <Group>
              <Avatar
                src={null}
                alt="profile picture"
                color="indigo"
                size="lg"
                radius="xl"
              />
              <Stack spacing={0}>
                <Text>John Doe</Text>
                <Text size="sm" color="dimmed">
                  john.doe@gmail.com
                </Text>
              </Stack>
            </Group>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={60}>
          <Group position="apart" px={20} py={10} sx={{ height: '100%' }}>
            <Group>
              <Title order={3}>BudgetFactor</Title>
              <Button variant="subtle" component={Link} to="/a" color="dark">
                Accounts
              </Button>
            </Group>
            <ActionIcon
              variant="default"
              onClick={() => toggleColorScheme()}
              size={30}
            >
              {colorScheme === 'dark' ? (
                <Sun size={16} />
              ) : (
                <MoonStars size={16} />
              )}
            </ActionIcon>
          </Group>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Outlet />
    </AppShell>
  );
};

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="a">
          <Route path=":accId" element={<AccountPage />} />
        </Route>
        <Route path="account">
          <Route path="new" element={<NewAccountPage />} />
        </Route>
      </Route>
    </Routes>
  );
};
export default Routing;
