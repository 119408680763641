import { Button, Group, Text, UnstyledButton } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  ContextMenu,
  ContextMenuDropdown,
  ContextMenuTarget,
} from 'components/ContextMenu';
import { ContextMenuItem } from 'components/ContextMenuItem';
import {
  AccountSummaryFragment,
  useAccountsSummaryQuery,
  useDeleteAccountMutation,
} from 'generated/graphql';
import { FC, forwardRef, useCallback } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { CirclePlus, Trash } from 'tabler-icons-react';

export interface AccountsSidebarProps {
  className?: string;
}

interface AccountButtonProps {
  account: AccountSummaryFragment;
  onContextMenu?: React.MouseEventHandler<HTMLAnchorElement>;
}

interface AccountButtonMenuProps
  extends React.ComponentPropsWithoutRef<'button'> {
  account: AccountSummaryFragment;
}

const AccountButton = forwardRef<HTMLAnchorElement, AccountButtonProps>(
  ({ account, onContextMenu, ...other }, ref) => {
    const match = useMatch(`/a/${account.id}`);

    return (
      <UnstyledButton
        ref={ref}
        component={Link}
        to={`/a/${account.id}`}
        sx={(theme) => ({
          display: 'block',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color:
            theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[6]
                : theme.colors.gray[1],
          },
        })}
        onContextMenu={onContextMenu}
        {...other}
      >
        <Group position="apart">
          <Text
            weight={500}
            sx={(theme) => ({
              // textDecoration: !!match ? 'underline' : 'none',
              color: !!match ? theme.colors.blue[6] : undefined,
              fontWeight: !!match ? 600 : undefined,
            })}
          >
            {account.name}
          </Text>
          <Text
            weight={500}
            color={account.currentAmount > 0 ? 'darkgreen' : 'darkred'}
          >
            {account.currentAmount} €
          </Text>
        </Group>
      </UnstyledButton>
    );
  }
);

const AccountButtonMenu: FC<AccountButtonMenuProps> = ({ account }) => {
  const modals = useModals();
  const [_, deleteAccount] = useDeleteAccountMutation();

  const onDeleteConfirm = useCallback(async () => {
    const { data, error } = await deleteAccount({ id: account.id });
    if (error || !data) {
      return;
    }
    showNotification({
      title: `Account deleted!`,
      message: `Account "${account.name}" has been deleted.`,
      color: 'red',
    });
  }, [account.id, account.name, deleteAccount]);
  const showDeleteModal = useCallback(() => {
    modals.openConfirmModal({
      title: `Delete account "${account.name}"`,
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete the account? All transactions and
          statistics tied to the account will be deleted.
        </Text>
      ),
      labels: { confirm: 'Delete account', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: onDeleteConfirm,
    });
  }, [onDeleteConfirm, account.name, modals]);

  return (
    <ContextMenu>
      <ContextMenuTarget>
        <AccountButton account={account} />
      </ContextMenuTarget>
      <ContextMenuDropdown>
        <ContextMenuItem
          onClick={() => {
            console.log('First item selected.');
          }}
        >
          First Item
        </ContextMenuItem>
        <ContextMenuItem
          icon={<Trash size={14} />}
          color="red"
          onClick={showDeleteModal}
        >
          Delete Account
        </ContextMenuItem>
      </ContextMenuDropdown>
    </ContextMenu>
  );
};

export const AccountsSidebar: FC<AccountsSidebarProps> = () => {
  const [result] = useAccountsSummaryQuery();
  const { data, fetching, error } = result;

  if (fetching) {
    return <Text>...</Text>;
  }
  if (error) {
    return <Text>Error</Text>;
  }

  return (
    <>
      {data?.accounts?.nodes?.map((acc) => {
        if (acc == null) return null;
        return <AccountButtonMenu account={acc} key={acc.id} />;
      })}

      <Button
        fullWidth
        my={10}
        leftIcon={<CirclePlus />}
        component={Link}
        to="/account/new"
        variant="subtle"
      >
        Add New Account
      </Button>
    </>
  );
};
