import { DefaultProps, Popover } from '@mantine/core';
import { useDidUpdate, useDisclosure } from '@mantine/hooks';
import { getContextItemIndex, isElement, useHovered } from '@mantine/utils';
import React, { cloneElement, createContext, FC, useContext, useRef } from 'react';

interface ContextMenuContextProps {
  toggleDropdown: () => void;
  closeDropdown: () => void;
  openDropdown: () => void;

  setHovered: (index: number) => void;
  getItemIndex: (node: HTMLButtonElement) => number;

  opened: boolean;
  hovered: number;
}

export const ContextMenuContext = createContext<ContextMenuContextProps | null>(null);

export interface ContextMenuProps {
  children: React.ReactNode;
  onClose?: () => void;
  onOpen?: () => void;
  closeOnItemClick?: boolean;
}

/**
 * Based on the Menu component of Mantine.
 *
 * Made because the Menu component does not work correctly when trying to make it open on right click.
 */
export const ContextMenu: FC<ContextMenuProps> = ({ children, onClose, onOpen }) => {
  const [hovered, { setHovered, resetHovered }] = useHovered();
  const [_opened, { open, close }] = useDisclosure(false, {
    onOpen: () => {
      typeof onOpen === 'function' && onOpen();
    },
    onClose: () => {
      typeof onClose === 'function' && onClose();
    }
  });

  const toggleDropdown = () => (_opened ? close() : open());
  const getItemIndex = (node: HTMLButtonElement) =>
    getContextItemIndex('[data-menu-item]', '[data-menu-dropdown]', node);

  useDidUpdate(() => {
    resetHovered();
  }, [_opened]);

  return (
    <ContextMenuContext.Provider
      value={{
        opened: _opened,
        toggleDropdown,
        openDropdown: open,
        closeDropdown: close,
        setHovered,
        hovered,
        getItemIndex
      }}
    >
      <Popover
        opened={_opened}
        onChange={(opened) => (opened ? open() : close())}
        defaultOpened={false}
        trapFocus={true}
        closeOnEscape={true}
        __staticSelector="Menu"
      >
        {children}
      </Popover>
    </ContextMenuContext.Provider>
  );
};

export interface ContextMenuTargetProps {
  children: React.ReactNode;
  refProp?: string;
}

export const ContextMenuTarget: FC<ContextMenuTargetProps> = ({ children, refProp = 'ref' }) => {
  if (!isElement(children)) {
    throw new Error('ContextMenuTarget component children should be an element or a component that accepts ref.');
  }

  const ctx = useContext(ContextMenuContext)!;

  const onContextMenu = (e: Event) => {
    e.preventDefault();
    ctx.toggleDropdown();
  };

  return (
    <Popover.Target refProp={refProp} popupType="menu">
      {cloneElement(children, {
        onContextMenu,
        'data-expanded': ctx.opened ? true : undefined
      })}
    </Popover.Target>
  );
};

export interface ContextMenuDropdownProps extends DefaultProps, React.ComponentPropsWithoutRef<'div'> {
  children?: React.ReactNode;
}

export const ContextMenuDropdown: FC<ContextMenuDropdownProps> = ({ children, ...others }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowUp') {
      event.preventDefault();
      wrapperRef.current?.querySelectorAll<HTMLButtonElement>('[data-menu-item]')[0].focus();
    }
  };

  return (
    <Popover.Dropdown p={4} role="menu" aria-orientation="vertical">
      <div
        tabIndex={-1}
        data-menu-dropdown
        data-autofocus
        onKeyDown={handleKeyDown}
        ref={wrapperRef}
        style={{
          outline: 0,
          display: 'flex',
          flexDirection: 'column'
        }}
        {...others}
      >
        {children}
      </div>
    </Popover.Dropdown>
  );
};
