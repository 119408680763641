import { Button, Group, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { FormTextInput } from 'forms/formInputs';
import { FC, useId } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type CreateCategoryModalProps = {
  categoryName: string;
  onSubmit: (data: CreateCategoryFormStruct) => void;
};
export interface CreateCategoryFormStruct {
  categoryName: string;
  categoryParent: string;
}

export const CreateCategoryModal: FC<
  ContextModalProps<CreateCategoryModalProps>
> = ({
  context,
  id,
  innerProps: { categoryName: _categoryName, onSubmit },
}) => {
  const form = useForm<CreateCategoryFormStruct>({
    defaultValues: {
      categoryName: _categoryName,
      categoryParent: '',
    },
  });
  const inputId = useId();

  const handleSubmit = (data: CreateCategoryFormStruct) => {
    onSubmit(data);
    context.closeModal(id, false);
  };
  const handleCancel = () => {
    context.closeModal(id, true);
  };

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <FormProvider {...form}>
        <Stack>
          <FormTextInput
            id={`${inputId}-categoryName`}
            name="categoryName"
            label="Category Name"
            rules={{ required: true }}
          />
          <FormTextInput
            id={`${inputId}-categoryParent`}
            name="categoryParent"
            label="Category Parent"
            rules={{ required: true }}
            data-autoFocus
          />
          <Group>
            <Button variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" color="green">
              Create
            </Button>
          </Group>
        </Stack>
      </FormProvider>
    </form>
  );
};
