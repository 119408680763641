import { Resolver as GraphCacheResolver, UpdateResolver as GraphCacheUpdateResolver, OptimisticMutationResolver as GraphCacheOptimisticMutationResolver, StorageAdapter as GraphCacheStorageAdapter } from '@urql/exchange-graphcache';
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: number;
};

export type Account = Node & {
  __typename?: 'Account';
  currentAmount: Scalars['Decimal'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  startingAmount: Scalars['Decimal'];
  transactions: Array<Transaction>;
};


export type AccountTransactionsArgs = {
  order?: InputMaybe<Array<TransactionSortInput>>;
  where?: InputMaybe<TransactionFilterInput>;
};

export type AccountFilterInput = {
  and?: InputMaybe<Array<AccountFilterInput>>;
  currentAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  deletedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountFilterInput>>;
  startingAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  transactions?: InputMaybe<ListFilterInputTypeOfTransactionFilterInput>;
};

export type AccountSortInput = {
  currentAmount?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  startingAmount?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type AccountsConnection = {
  __typename?: 'AccountsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AccountsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Account>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AccountsEdge = {
  __typename?: 'AccountsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
};

export type Actor = {
  __typename?: 'Actor';
  defaultCategory: Category;
  defaultCategoryId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  transactions: Array<Transaction>;
};


export type ActorTransactionsArgs = {
  order?: InputMaybe<Array<TransactionSortInput>>;
  where?: InputMaybe<TransactionFilterInput>;
};

export type ActorFilterInput = {
  and?: InputMaybe<Array<ActorFilterInput>>;
  defaultCategory?: InputMaybe<CategoryFilterInput>;
  defaultCategoryId?: InputMaybe<IdOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ActorFilterInput>>;
  transactions?: InputMaybe<ListFilterInputTypeOfTransactionFilterInput>;
};

export type ActorSortInput = {
  defaultCategory?: InputMaybe<CategorySortInput>;
  defaultCategoryId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type AddAccountInput = {
  name: Scalars['String'];
  startingAmount: Scalars['Decimal'];
};

export type AddAccountPayload = {
  __typename?: 'AddAccountPayload';
  account?: Maybe<Account>;
};

export type AddTransactionError = EntityNotFoundError | InvalidCreateReferenceError;

export type AddTransactionInput = {
  accountId: Scalars['ID'];
  actorId?: InputMaybe<Scalars['ID']>;
  actorName?: InputMaybe<Scalars['String']>;
  amount: Scalars['Decimal'];
  categoryId?: InputMaybe<Scalars['ID']>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryParent?: InputMaybe<Scalars['String']>;
  dateStamp: Scalars['Date'];
  description?: InputMaybe<Scalars['String']>;
};

export type AddTransactionPayload = {
  __typename?: 'AddTransactionPayload';
  errors?: Maybe<Array<AddTransactionError>>;
  transaction?: Maybe<Transaction>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentCategory: Scalars['String'];
  transactions: Array<Transaction>;
};


export type CategoryTransactionsArgs = {
  order?: InputMaybe<Array<TransactionSortInput>>;
  where?: InputMaybe<TransactionFilterInput>;
};

export type CategoryFilterInput = {
  and?: InputMaybe<Array<CategoryFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CategoryFilterInput>>;
  parentCategory?: InputMaybe<StringOperationFilterInput>;
  transactions?: InputMaybe<ListFilterInputTypeOfTransactionFilterInput>;
};

export type CategorySortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentCategory?: InputMaybe<SortEnumType>;
};

export type ComparableDateOnlyOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<Scalars['Date']>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  neq?: InputMaybe<Scalars['Date']>;
  ngt?: InputMaybe<Scalars['Date']>;
  ngte?: InputMaybe<Scalars['Date']>;
  nin?: InputMaybe<Array<Scalars['Date']>>;
  nlt?: InputMaybe<Scalars['Date']>;
  nlte?: InputMaybe<Scalars['Date']>;
};

export type ComparableDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<Scalars['Decimal']>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type DeleteAccountInput = {
  id: Scalars['ID'];
};

export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  account?: Maybe<Account>;
};

export type EntityNotFoundError = Error & {
  __typename?: 'EntityNotFoundError';
  entityName: Scalars['String'];
  message: Scalars['String'];
};

export type Error = {
  message: Scalars['String'];
};

export type GenerateMockTransactionsInput = {
  accountId: Scalars['ID'];
  count: Scalars['Int'];
};

export type GenerateMockTransactionsPayload = {
  __typename?: 'GenerateMockTransactionsPayload';
  transaction?: Maybe<Array<Transaction>>;
};

export type IdOperationFilterInput = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  neq?: InputMaybe<Scalars['ID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type InvalidCreateReferenceError = Error & {
  __typename?: 'InvalidCreateReferenceError';
  message: Scalars['String'];
  optionA: Array<Scalars['String']>;
  optionB: Array<Scalars['String']>;
  paramName?: Maybe<Scalars['String']>;
};

export type ListFilterInputTypeOfTransactionFilterInput = {
  all?: InputMaybe<TransactionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TransactionFilterInput>;
  some?: InputMaybe<TransactionFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAccount: AddAccountPayload;
  addTransaction: AddTransactionPayload;
  deleteAccount: DeleteAccountPayload;
  generateMockTransactions: GenerateMockTransactionsPayload;
};


export type MutationAddAccountArgs = {
  input: AddAccountInput;
};


export type MutationAddTransactionArgs = {
  input: AddTransactionInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationGenerateMockTransactionsArgs = {
  input: GenerateMockTransactionsInput;
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accounts?: Maybe<AccountsConnection>;
  actor?: Maybe<Actor>;
  actors: Array<Actor>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  transactions?: Maybe<TransactionsConnection>;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AccountSortInput>>;
  where?: InputMaybe<AccountFilterInput>;
};


export type QueryActorArgs = {
  id: Scalars['ID'];
};


export type QueryActorsArgs = {
  where?: InputMaybe<ActorFilterInput>;
};


export type QueryCategoriesArgs = {
  where?: InputMaybe<CategoryFilterInput>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TransactionSortInput>>;
  where?: InputMaybe<TransactionFilterInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  account: Account;
  accountId: Scalars['ID'];
  amount?: Maybe<Scalars['Decimal']>;
  category: Category;
  categoryId: Scalars['ID'];
  dateStamp: Scalars['Date'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  secondActor: Actor;
  secondActorId: Scalars['ID'];
};

export type TransactionFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<IdOperationFilterInput>;
  amount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  and?: InputMaybe<Array<TransactionFilterInput>>;
  category?: InputMaybe<CategoryFilterInput>;
  categoryId?: InputMaybe<IdOperationFilterInput>;
  dateStamp?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  deletedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<TransactionFilterInput>>;
  secondActor?: InputMaybe<ActorFilterInput>;
  secondActorId?: InputMaybe<IdOperationFilterInput>;
};

export type TransactionSortInput = {
  account?: InputMaybe<AccountSortInput>;
  accountId?: InputMaybe<SortEnumType>;
  amount?: InputMaybe<SortEnumType>;
  category?: InputMaybe<CategorySortInput>;
  categoryId?: InputMaybe<SortEnumType>;
  dateStamp?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  secondActor?: InputMaybe<ActorSortInput>;
  secondActorId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type TransactionsConnection = {
  __typename?: 'TransactionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TransactionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Transaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TransactionsEdge = {
  __typename?: 'TransactionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Transaction;
};

export type AccountSummaryFragment = { __typename?: 'Account', id: string, name: string, currentAmount: number };

export type AccountsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsSummaryQuery = { __typename?: 'Query', accounts?: { __typename?: 'AccountsConnection', nodes?: Array<{ __typename?: 'Account', id: string, name: string, currentAmount: number } | null> | null } | null };

export type AccountDetailsQueryVariables = Exact<{
  accId: Scalars['ID'];
}>;


export type AccountDetailsQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, name: string, startingAmount: number, currentAmount: number } | null };

export type GenerateFakeDataMutationVariables = Exact<{
  count: Scalars['Int'];
  accId: Scalars['ID'];
}>;


export type GenerateFakeDataMutation = { __typename?: 'Mutation', generateMockTransactions: { __typename?: 'GenerateMockTransactionsPayload', transaction?: Array<{ __typename?: 'Transaction', id: string, description?: string | null, amount?: number | null }> | null } };

export type CreateAccountMutationVariables = Exact<{
  account: AddAccountInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', addAccount: { __typename?: 'AddAccountPayload', account?: { __typename?: 'Account', id: string, name: string, currentAmount: number } | null } };

export type DeleteAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount: { __typename?: 'DeleteAccountPayload', account?: { __typename?: 'Account', id: string, name: string, currentAmount: number } | null } };

export type AccountTransactionsQueryVariables = Exact<{
  count: Scalars['Int'];
  endCursor?: InputMaybe<Scalars['String']>;
  accId: Scalars['ID'];
}>;


export type AccountTransactionsQuery = { __typename?: 'Query', transactions?: { __typename?: 'TransactionsConnection', pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'Transaction', id: string, dateStamp: any, description?: string | null, amount?: number | null, category: { __typename?: 'Category', id: string, name: string }, secondActor: { __typename?: 'Actor', id: string, name: string } }> | null } | null };

export type TransactionFieldsFragment = { __typename?: 'Transaction', id: string, dateStamp: any, description?: string | null, amount?: number | null, category: { __typename?: 'Category', id: string, name: string }, secondActor: { __typename?: 'Actor', id: string, name: string } };

export type AddTransactionMutationVariables = Exact<{
  transaction: AddTransactionInput;
}>;


export type AddTransactionMutation = { __typename?: 'Mutation', addTransaction: { __typename?: 'AddTransactionPayload', transaction?: { __typename?: 'Transaction', id: string, dateStamp: any, description?: string | null, amount?: number | null, category: { __typename?: 'Category', id: string, name: string }, secondActor: { __typename?: 'Actor', id: string, name: string } } | null, errors?: Array<{ __typename?: 'EntityNotFoundError', message: string } | { __typename?: 'InvalidCreateReferenceError', message: string, optionA: Array<string>, optionB: Array<string> }> | null } };

export type GetAllActorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllActorsQuery = { __typename?: 'Query', actors: Array<{ __typename?: 'Actor', id: string, name: string, defaultCategoryId: string }> };

export type ActorSummaryFragment = { __typename?: 'Actor', id: string, name: string, defaultCategoryId: string };

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, name: string, parentCategory: string }> };

export type CategorySummaryFragment = { __typename?: 'Category', id: string, name: string, parentCategory: string };

export const AccountSummaryFragmentDoc = gql`
    fragment AccountSummary on Account {
  id
  name
  currentAmount
}
    `;
export const TransactionFieldsFragmentDoc = gql`
    fragment TransactionFields on Transaction {
  id
  dateStamp
  description
  amount
  category {
    id
    name
  }
  secondActor {
    id
    name
  }
}
    `;
export const ActorSummaryFragmentDoc = gql`
    fragment ActorSummary on Actor {
  id
  name
  defaultCategoryId
}
    `;
export const CategorySummaryFragmentDoc = gql`
    fragment CategorySummary on Category {
  id
  name
  parentCategory
}
    `;
export const AccountsSummaryDocument = gql`
    query accountsSummary {
  accounts(order: {id: ASC}) {
    nodes {
      ...AccountSummary
    }
  }
}
    ${AccountSummaryFragmentDoc}`;

export function useAccountsSummaryQuery(options?: Omit<Urql.UseQueryArgs<AccountsSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<AccountsSummaryQuery>({ query: AccountsSummaryDocument, ...options });
};
export const AccountDetailsDocument = gql`
    query accountDetails($accId: ID!) {
  account(id: $accId) {
    id
    name
    startingAmount
    currentAmount
  }
}
    `;

export function useAccountDetailsQuery(options: Omit<Urql.UseQueryArgs<AccountDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<AccountDetailsQuery>({ query: AccountDetailsDocument, ...options });
};
export const GenerateFakeDataDocument = gql`
    mutation generateFakeData($count: Int!, $accId: ID!) {
  generateMockTransactions(input: {accountId: $accId, count: $count}) {
    transaction {
      id
      description
      amount
    }
  }
}
    `;

export function useGenerateFakeDataMutation() {
  return Urql.useMutation<GenerateFakeDataMutation, GenerateFakeDataMutationVariables>(GenerateFakeDataDocument);
};
export const CreateAccountDocument = gql`
    mutation createAccount($account: AddAccountInput!) {
  addAccount(input: $account) {
    account {
      ...AccountSummary
    }
  }
}
    ${AccountSummaryFragmentDoc}`;

export function useCreateAccountMutation() {
  return Urql.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument);
};
export const DeleteAccountDocument = gql`
    mutation deleteAccount($id: ID!) {
  deleteAccount(input: {id: $id}) {
    account {
      ...AccountSummary
    }
  }
}
    ${AccountSummaryFragmentDoc}`;

export function useDeleteAccountMutation() {
  return Urql.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument);
};
export const AccountTransactionsDocument = gql`
    query accountTransactions($count: Int!, $endCursor: String, $accId: ID!) {
  transactions(
    first: $count
    after: $endCursor
    where: {accountId: {eq: $accId}}
    order: {dateStamp: DESC}
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    nodes {
      ...TransactionFields
    }
  }
}
    ${TransactionFieldsFragmentDoc}`;

export function useAccountTransactionsQuery(options: Omit<Urql.UseQueryArgs<AccountTransactionsQueryVariables>, 'query'>) {
  return Urql.useQuery<AccountTransactionsQuery>({ query: AccountTransactionsDocument, ...options });
};
export const AddTransactionDocument = gql`
    mutation addTransaction($transaction: AddTransactionInput!) {
  addTransaction(input: $transaction) {
    transaction {
      ...TransactionFields
    }
    errors {
      ... on InvalidCreateReferenceError {
        message
        optionA
        optionB
      }
      ... on Error {
        message
      }
    }
  }
}
    ${TransactionFieldsFragmentDoc}`;

export function useAddTransactionMutation() {
  return Urql.useMutation<AddTransactionMutation, AddTransactionMutationVariables>(AddTransactionDocument);
};
export const GetAllActorsDocument = gql`
    query getAllActors {
  actors {
    ...ActorSummary
  }
}
    ${ActorSummaryFragmentDoc}`;

export function useGetAllActorsQuery(options?: Omit<Urql.UseQueryArgs<GetAllActorsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllActorsQuery>({ query: GetAllActorsDocument, ...options });
};
export const GetAllCategoriesDocument = gql`
    query getAllCategories {
  categories {
    ...CategorySummary
  }
}
    ${CategorySummaryFragmentDoc}`;

export function useGetAllCategoriesQuery(options?: Omit<Urql.UseQueryArgs<GetAllCategoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCategoriesQuery>({ query: GetAllCategoriesDocument, ...options });
};
import { IntrospectionQuery } from 'graphql';
export default {
  "__schema": {
    "queryType": {
      "name": "Query"
    },
    "mutationType": {
      "name": "Mutation"
    },
    "subscriptionType": null,
    "types": [
      {
        "kind": "OBJECT",
        "name": "Account",
        "fields": [
          {
            "name": "currentAmount",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "deletedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "deletedBy",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startingAmount",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "transactions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Transaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "order",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          }
        ],
        "interfaces": [
          {
            "kind": "INTERFACE",
            "name": "Node"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "AccountsConnection",
        "fields": [
          {
            "name": "edges",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "OBJECT",
                  "name": "AccountsEdge",
                  "ofType": null
                }
              }
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "Account",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "pageInfo",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "PageInfo",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AccountsEdge",
        "fields": [
          {
            "name": "cursor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "node",
            "type": {
              "kind": "OBJECT",
              "name": "Account",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Actor",
        "fields": [
          {
            "name": "defaultCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Category",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "defaultCategoryId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "transactions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Transaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "order",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AddAccountPayload",
        "fields": [
          {
            "name": "account",
            "type": {
              "kind": "OBJECT",
              "name": "Account",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "UNION",
        "name": "AddTransactionError",
        "possibleTypes": [
          {
            "kind": "OBJECT",
            "name": "EntityNotFoundError"
          },
          {
            "kind": "OBJECT",
            "name": "InvalidCreateReferenceError"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "AddTransactionPayload",
        "fields": [
          {
            "name": "errors",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "UNION",
                  "name": "AddTransactionError",
                  "ofType": null
                }
              }
            },
            "args": []
          },
          {
            "name": "transaction",
            "type": {
              "kind": "OBJECT",
              "name": "Transaction",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Category",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "parentCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "transactions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Transaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "order",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "DeleteAccountPayload",
        "fields": [
          {
            "name": "account",
            "type": {
              "kind": "OBJECT",
              "name": "Account",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "EntityNotFoundError",
        "fields": [
          {
            "name": "entityName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "message",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": [
          {
            "kind": "INTERFACE",
            "name": "Error"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Error",
        "fields": [
          {
            "name": "message",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": [],
        "possibleTypes": [
          {
            "kind": "OBJECT",
            "name": "EntityNotFoundError"
          },
          {
            "kind": "OBJECT",
            "name": "InvalidCreateReferenceError"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "GenerateMockTransactionsPayload",
        "fields": [
          {
            "name": "transaction",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "OBJECT",
                  "name": "Transaction",
                  "ofType": null
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "InvalidCreateReferenceError",
        "fields": [
          {
            "name": "message",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "optionA",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "optionB",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "paramName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": [
          {
            "kind": "INTERFACE",
            "name": "Error"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "Mutation",
        "fields": [
          {
            "name": "addAccount",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AddAccountPayload",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "input",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "addTransaction",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AddTransactionPayload",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "input",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAccount",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "DeleteAccountPayload",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "input",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "generateMockTransactions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "GenerateMockTransactionsPayload",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "input",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INTERFACE",
        "name": "Node",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": [],
        "possibleTypes": [
          {
            "kind": "OBJECT",
            "name": "Account"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "PageInfo",
        "fields": [
          {
            "name": "endCursor",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "hasNextPage",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "hasPreviousPage",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startCursor",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Query",
        "fields": [
          {
            "name": "account",
            "type": {
              "kind": "OBJECT",
              "name": "Account",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "accounts",
            "type": {
              "kind": "OBJECT",
              "name": "AccountsConnection",
              "ofType": null
            },
            "args": [
              {
                "name": "after",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "before",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "first",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "last",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "order",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "actor",
            "type": {
              "kind": "OBJECT",
              "name": "Actor",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "actors",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Actor",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "categories",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Category",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "category",
            "type": {
              "kind": "OBJECT",
              "name": "Category",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "node",
            "type": {
              "kind": "INTERFACE",
              "name": "Node",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "INTERFACE",
                  "name": "Node",
                  "ofType": null
                }
              }
            },
            "args": [
              {
                "name": "ids",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "transactions",
            "type": {
              "kind": "OBJECT",
              "name": "TransactionsConnection",
              "ofType": null
            },
            "args": [
              {
                "name": "after",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "before",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "first",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "last",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "order",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Transaction",
        "fields": [
          {
            "name": "account",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Account",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "accountId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "amount",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "category",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Category",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "dateStamp",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "deletedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "deletedBy",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "secondActor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Actor",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "secondActorId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "TransactionsConnection",
        "fields": [
          {
            "name": "edges",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "OBJECT",
                  "name": "TransactionsEdge",
                  "ofType": null
                }
              }
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "OBJECT",
                  "name": "Transaction",
                  "ofType": null
                }
              }
            },
            "args": []
          },
          {
            "name": "pageInfo",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "PageInfo",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "TransactionsEdge",
        "fields": [
          {
            "name": "cursor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "node",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Transaction",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "SCALAR",
        "name": "Any"
      }
    ],
    "directives": []
  }
} as unknown as IntrospectionQuery;
export type WithTypename<T extends { __typename?: any }> = Partial<T> & { __typename: NonNullable<T['__typename']> };

export type GraphCacheKeysConfig = {
  Account?: (data: WithTypename<Account>) => null | string,
  AccountsConnection?: (data: WithTypename<AccountsConnection>) => null | string,
  AccountsEdge?: (data: WithTypename<AccountsEdge>) => null | string,
  Actor?: (data: WithTypename<Actor>) => null | string,
  AddAccountPayload?: (data: WithTypename<AddAccountPayload>) => null | string,
  AddTransactionPayload?: (data: WithTypename<AddTransactionPayload>) => null | string,
  Category?: (data: WithTypename<Category>) => null | string,
  DeleteAccountPayload?: (data: WithTypename<DeleteAccountPayload>) => null | string,
  EntityNotFoundError?: (data: WithTypename<EntityNotFoundError>) => null | string,
  GenerateMockTransactionsPayload?: (data: WithTypename<GenerateMockTransactionsPayload>) => null | string,
  InvalidCreateReferenceError?: (data: WithTypename<InvalidCreateReferenceError>) => null | string,
  PageInfo?: (data: WithTypename<PageInfo>) => null | string,
  Transaction?: (data: WithTypename<Transaction>) => null | string,
  TransactionsConnection?: (data: WithTypename<TransactionsConnection>) => null | string,
  TransactionsEdge?: (data: WithTypename<TransactionsEdge>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    account?: GraphCacheResolver<WithTypename<Query>, QueryAccountArgs, WithTypename<Account> | string>,
    accounts?: GraphCacheResolver<WithTypename<Query>, QueryAccountsArgs, WithTypename<AccountsConnection> | string>,
    actor?: GraphCacheResolver<WithTypename<Query>, QueryActorArgs, WithTypename<Actor> | string>,
    actors?: GraphCacheResolver<WithTypename<Query>, QueryActorsArgs, Array<WithTypename<Actor> | string>>,
    categories?: GraphCacheResolver<WithTypename<Query>, QueryCategoriesArgs, Array<WithTypename<Category> | string>>,
    category?: GraphCacheResolver<WithTypename<Query>, QueryCategoryArgs, WithTypename<Category> | string>,
    node?: GraphCacheResolver<WithTypename<Query>, QueryNodeArgs, WithTypename<Account> | string>,
    nodes?: GraphCacheResolver<WithTypename<Query>, QueryNodesArgs, Array<WithTypename<Account> | string>>,
    transactions?: GraphCacheResolver<WithTypename<Query>, QueryTransactionsArgs, WithTypename<TransactionsConnection> | string>
  },
  Account?: {
    currentAmount?: GraphCacheResolver<WithTypename<Account>, Record<string, never>, Scalars['Decimal'] | string>,
    deletedAt?: GraphCacheResolver<WithTypename<Account>, Record<string, never>, Scalars['DateTime'] | string>,
    deletedBy?: GraphCacheResolver<WithTypename<Account>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<Account>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<Account>, Record<string, never>, Scalars['String'] | string>,
    startingAmount?: GraphCacheResolver<WithTypename<Account>, Record<string, never>, Scalars['Decimal'] | string>,
    transactions?: GraphCacheResolver<WithTypename<Account>, AccountTransactionsArgs, Array<WithTypename<Transaction> | string>>
  },
  AccountsConnection?: {
    edges?: GraphCacheResolver<WithTypename<AccountsConnection>, Record<string, never>, Array<WithTypename<AccountsEdge> | string>>,
    nodes?: GraphCacheResolver<WithTypename<AccountsConnection>, Record<string, never>, Array<WithTypename<Account> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<AccountsConnection>, Record<string, never>, WithTypename<PageInfo> | string>
  },
  AccountsEdge?: {
    cursor?: GraphCacheResolver<WithTypename<AccountsEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<AccountsEdge>, Record<string, never>, WithTypename<Account> | string>
  },
  Actor?: {
    defaultCategory?: GraphCacheResolver<WithTypename<Actor>, Record<string, never>, WithTypename<Category> | string>,
    defaultCategoryId?: GraphCacheResolver<WithTypename<Actor>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<Actor>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<Actor>, Record<string, never>, Scalars['String'] | string>,
    transactions?: GraphCacheResolver<WithTypename<Actor>, ActorTransactionsArgs, Array<WithTypename<Transaction> | string>>
  },
  AddAccountPayload?: {
    account?: GraphCacheResolver<WithTypename<AddAccountPayload>, Record<string, never>, WithTypename<Account> | string>
  },
  AddTransactionPayload?: {
    errors?: GraphCacheResolver<WithTypename<AddTransactionPayload>, Record<string, never>, Array<WithTypename<AddTransactionError> | string>>,
    transaction?: GraphCacheResolver<WithTypename<AddTransactionPayload>, Record<string, never>, WithTypename<Transaction> | string>
  },
  Category?: {
    id?: GraphCacheResolver<WithTypename<Category>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<Category>, Record<string, never>, Scalars['String'] | string>,
    parentCategory?: GraphCacheResolver<WithTypename<Category>, Record<string, never>, Scalars['String'] | string>,
    transactions?: GraphCacheResolver<WithTypename<Category>, CategoryTransactionsArgs, Array<WithTypename<Transaction> | string>>
  },
  DeleteAccountPayload?: {
    account?: GraphCacheResolver<WithTypename<DeleteAccountPayload>, Record<string, never>, WithTypename<Account> | string>
  },
  EntityNotFoundError?: {
    entityName?: GraphCacheResolver<WithTypename<EntityNotFoundError>, Record<string, never>, Scalars['String'] | string>,
    message?: GraphCacheResolver<WithTypename<EntityNotFoundError>, Record<string, never>, Scalars['String'] | string>
  },
  GenerateMockTransactionsPayload?: {
    transaction?: GraphCacheResolver<WithTypename<GenerateMockTransactionsPayload>, Record<string, never>, Array<WithTypename<Transaction> | string>>
  },
  InvalidCreateReferenceError?: {
    message?: GraphCacheResolver<WithTypename<InvalidCreateReferenceError>, Record<string, never>, Scalars['String'] | string>,
    optionA?: GraphCacheResolver<WithTypename<InvalidCreateReferenceError>, Record<string, never>, Array<Scalars['String'] | string>>,
    optionB?: GraphCacheResolver<WithTypename<InvalidCreateReferenceError>, Record<string, never>, Array<Scalars['String'] | string>>,
    paramName?: GraphCacheResolver<WithTypename<InvalidCreateReferenceError>, Record<string, never>, Scalars['String'] | string>
  },
  PageInfo?: {
    endCursor?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['String'] | string>,
    hasNextPage?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    hasPreviousPage?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    startCursor?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['String'] | string>
  },
  Transaction?: {
    account?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, WithTypename<Account> | string>,
    accountId?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['ID'] | string>,
    amount?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['Decimal'] | string>,
    category?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, WithTypename<Category> | string>,
    categoryId?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['ID'] | string>,
    dateStamp?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['Date'] | string>,
    deletedAt?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['DateTime'] | string>,
    deletedBy?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['ID'] | string>,
    secondActor?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, WithTypename<Actor> | string>,
    secondActorId?: GraphCacheResolver<WithTypename<Transaction>, Record<string, never>, Scalars['ID'] | string>
  },
  TransactionsConnection?: {
    edges?: GraphCacheResolver<WithTypename<TransactionsConnection>, Record<string, never>, Array<WithTypename<TransactionsEdge> | string>>,
    nodes?: GraphCacheResolver<WithTypename<TransactionsConnection>, Record<string, never>, Array<WithTypename<Transaction> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<TransactionsConnection>, Record<string, never>, WithTypename<PageInfo> | string>
  },
  TransactionsEdge?: {
    cursor?: GraphCacheResolver<WithTypename<TransactionsEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<TransactionsEdge>, Record<string, never>, WithTypename<Transaction> | string>
  }
};

export type GraphCacheOptimisticUpdaters = {
  addAccount?: GraphCacheOptimisticMutationResolver<MutationAddAccountArgs, WithTypename<AddAccountPayload>>,
  addTransaction?: GraphCacheOptimisticMutationResolver<MutationAddTransactionArgs, WithTypename<AddTransactionPayload>>,
  deleteAccount?: GraphCacheOptimisticMutationResolver<MutationDeleteAccountArgs, WithTypename<DeleteAccountPayload>>,
  generateMockTransactions?: GraphCacheOptimisticMutationResolver<MutationGenerateMockTransactionsArgs, WithTypename<GenerateMockTransactionsPayload>>
};

export type GraphCacheUpdaters = {
  Mutation?: {
    addAccount?: GraphCacheUpdateResolver<{ addAccount: WithTypename<AddAccountPayload> }, MutationAddAccountArgs>,
    addTransaction?: GraphCacheUpdateResolver<{ addTransaction: WithTypename<AddTransactionPayload> }, MutationAddTransactionArgs>,
    deleteAccount?: GraphCacheUpdateResolver<{ deleteAccount: WithTypename<DeleteAccountPayload> }, MutationDeleteAccountArgs>,
    generateMockTransactions?: GraphCacheUpdateResolver<{ generateMockTransactions: WithTypename<GenerateMockTransactionsPayload> }, MutationGenerateMockTransactionsArgs>
  },
  Subscription?: {},
};

export type GraphCacheConfig = {
  schema?: IntrospectionData,
  updates?: GraphCacheUpdaters,
  keys?: GraphCacheKeysConfig,
  optimistic?: GraphCacheOptimisticUpdaters,
  resolvers?: GraphCacheResolvers,
  storage?: GraphCacheStorageAdapter
};