import { Box, createStyles, DefaultProps, MantineColor } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import {
  createEventHandler,
  createPolymorphicComponent,
  createScopedKeydownHandler,
} from '@mantine/utils';
import { forwardRef, useContext, useRef } from 'react';
import { ContextMenuContext } from './ContextMenu';

export interface ContextMenuItemProps extends DefaultProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  color?: MantineColor;
  rightSection?: React.ReactNode;
  disabled?: boolean;
}

const useStyles = createStyles(
  (theme, { color }: { color?: MantineColor }) => ({
    item: {
      ...theme.fn.fontStyles(),
      fontSize: theme.fontSizes.sm,
      border: 0,
      backgroundColor: 'transparent',
      outline: 0,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      cursor: 'pointer',
      borderRadius: theme.radius,
      color: color
        ? theme.fn.themeColor(color, theme.colorScheme === 'dark' ? 5 : 7)
        : theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.black,

      '&:disabled': {
        color:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[3]
            : theme.colors.gray[5],
        pointerEvents: 'none',
      },

      '&:hover': {
        backgroundColor: color
          ? theme.fn.variant({ variant: 'light', color }).background
          : theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors.dark[3], 0.35)
          : theme.colors.gray[0],
      },
    },

    itemInner: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },

    itemBody: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
    },

    itemIcon: {
      marginRight: theme.spacing.xs,
      '& *': {
        display: 'block',
      },
    },

    itemLabel: {
      lineHeight: 1,
      paddingTop: '3px',
    },
  })
);

const _ContextMenuItem = forwardRef<HTMLButtonElement, ContextMenuItemProps>(
  (
    { className, children, icon, color, rightSection, disabled, ...others },
    ref
  ) => {
    const ctx = useContext(ContextMenuContext)!;
    const { classes, cx, theme } = useStyles({ color });
    const itemRef = useRef<HTMLButtonElement | null>(null);

    const itemIndex = ctx.getItemIndex(itemRef.current!);
    const _others: any = others;

    const handleMouseLeave = createEventHandler(_others.onMouseLeave, () =>
      ctx.setHovered(-1)
    );
    const handleMouseEnter = createEventHandler(_others.onMouseEnter, () =>
      ctx.setHovered(ctx.getItemIndex(itemRef.current!))
    );

    const handleClick = createEventHandler(_others.onClick, () => {
      console.log('Closing dropdown');
      ctx.closeDropdown();
    });
    const handleFocus = createEventHandler(_others.onFocus, () =>
      ctx.setHovered(ctx.getItemIndex(itemRef.current!))
    );

    return (
      <Box
        component="button"
        {...others}
        tabIndex={-1}
        ref={useMergedRef(itemRef, ref)}
        className={cx(classes.item, className)}
        type="button"
        role="menuitem"
        data-menu-item
        data-hovered={ctx.hovered === itemIndex ? true : undefined}
        color={color}
        disabled={disabled}
        onClick={handleClick}
        onFocus={handleFocus}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={createScopedKeydownHandler({
          siblingSelector: '[data-menu-item]',
          parentSelector: '[data-menu-dropdown]',
          activateOnFocus: false,
          dir: theme.dir,
          orientation: 'vertical',
          onKeyDown: _others.onKeyDown,
        })}
      >
        <div className={classes.itemInner}>
          {icon && <div className={classes.itemIcon}>{icon}</div>}

          <div className={classes.itemBody}>
            <div className={classes.itemLabel}>{children}</div>
            {rightSection}
          </div>
        </div>
      </Box>
    );
  }
);

export const ContextMenuItem = createPolymorphicComponent<
  'button',
  ContextMenuItemProps
>(_ContextMenuItem);
