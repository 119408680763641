import { createStyles, Skeleton, Spoiler } from '@mantine/core';
import { FC, forwardRef, useCallback, useState } from 'react';
import { TransactionFieldsFragment, useAccountTransactionsQuery } from '../../generated/graphql';
import { useInView } from 'react-intersection-observer';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import { useTableStyles } from '../../styles/table';
import { useTransactionStore } from '../../store/store';

const useTransactionStyles = createStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
    width: '100%'
  },

  newRow: {
    '> td': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[9] : theme.colors.green[1]
    },

    // Increase specificity to overwrite the base table hover style.
    '&:hover > td:nth-of-type(n)': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[8] : theme.colors.green[2]
    }
  },

  currency: {
    textAlign: 'end'
  }
}));

interface TransactionPageCursor {
  count: number;
  after?: string;
}

export interface TransactionTableProps {
  className?: string;
  accountId: string;
}

export const TransactionTable: FC<TransactionTableProps> = ({ accountId, className }) => {
  const [pageVariables, setPageVariables] = useState<TransactionPageCursor[]>([
    {
      count: 50
    }
  ]);
  const newTransactions = useTransactionStore((state) => state.newTransactions[accountId] ?? []);

  const { classes, cx } = useTransactionStyles();
  const { classes: tableClasses } = useTableStyles();

  return (
    <table className={cx(tableClasses.table, classes.table, className)}>
      <thead className={cx(tableClasses.tableHeader)}>
        <tr>
          <th style={{ width: '7rem' }}>Date</th>
          <th style={{ width: '8rem' }}>Category</th>
          <th style={{ width: 'calc(100% - 37rem)' }}>Description</th>
          <th style={{ width: '14rem' }}>Actor</th>
          <th style={{ width: '8rem' }}>Amount</th>
        </tr>
      </thead>
      <tbody className={cx(tableClasses.tableBody)}>
        {newTransactions.map((t, i) => (
          <TransactionRow key={`newTrx-${i}`} transaction={t} variant="new" />
        ))}
        {pageVariables.map((page, i) => (
          <TransactionPage
            key={`trx-page-${i}`}
            accountId={accountId}
            isLastPage={i === pageVariables.length - 1}
            cursor={page}
            onLoadMore={(after) => setPageVariables((pageVariables) => [...pageVariables, { after, count: 50 }])}
          />
        ))}
      </tbody>
    </table>
  );
};

interface TransactionPageProps {
  cursor: TransactionPageCursor;
  isLastPage: boolean;
  accountId: string;
  onLoadMore: (endCursor: string) => void;
}

const TransactionPage: FC<TransactionPageProps> = ({ cursor, isLastPage, accountId, onLoadMore }) => {
  const [{ data, fetching, error }] = useAccountTransactionsQuery({
    variables: {
      accId: accountId,
      count: cursor.count,
      endCursor: cursor.after
    }
  });

  const newTransactions = useTransactionStore((state) => state.newTransactions[accountId] ?? []);

  const handleLoadMore = useCallback(() => {
    if (!data?.transactions?.pageInfo.hasNextPage || !data.transactions.pageInfo.endCursor) {
      return;
    }
    onLoadMore(data.transactions.pageInfo.endCursor);
  }, [data?.transactions?.pageInfo, onLoadMore]);

  if (fetching || error) {
    return <TransactionLoadingRow />;
  }

  return (
    <>
      {data?.transactions?.nodes
        ?.filter((node) => !newTransactions?.find((t) => t.id === node.id))
        .map((node) => (
          <TransactionRow key={node.id} transaction={node} />
        ))}
      {isLastPage && data?.transactions?.pageInfo.hasNextPage && (
        <TransactionTableLoadTrigger onView={handleLoadMore} />
      )}
    </>
  );
};

interface TransactionRowProps {
  transaction: TransactionFieldsFragment;
  variant?: 'default' | 'new';
}

const TransactionRow: FC<TransactionRowProps> = ({ transaction, variant }) => {
  const { classes, cx } = useTransactionStyles();

  return (
    <tr className={cx({ [classes.newRow]: variant === 'new' })}>
      <td>{transaction.dateStamp}</td>
      <td>{transaction.category.name}</td>
      <td>
        <Spoiler
          hideLabel={<ChevronUp size={20} />}
          showLabel={<ChevronDown />}
          maxHeight={25}
          styles={{
            root: {
              display: 'flex'
            },
            control: { lineHeight: 1.0 }
          }}
        >
          {transaction.description}
        </Spoiler>
      </td>
      <td>{transaction.secondActor.name}</td>
      <td>{transaction.amount?.toLocaleString(undefined, { style: 'currency', currency: 'EUR' })}</td>
    </tr>
  );
};

const TransactionTableLoadTrigger: FC<{ onView: () => void }> = ({ onView }) => {
  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        onView();
      }
    },
    triggerOnce: true,
    rootMargin: '50px 0px 0px 0px'
  });

  return <TransactionLoadingRow ref={ref} />;
};

const TransactionLoadingRow = forwardRef<HTMLTableDataCellElement, {}>((_, ref) => {
  return (
    <tr>
      <td ref={ref}>
        <Skeleton height={16} radius="xl" my={4} />
      </td>
      <td>
        <Skeleton height={16} radius="xl" my={4} />
      </td>
      <td>
        <Skeleton height={16} radius="xl" my={4} />
      </td>
      <td>
        <Skeleton height={16} radius="xl" my={4} />
      </td>
      <td>
        <Skeleton height={16} radius="xl" my={4} />
      </td>
    </tr>
  );
});
