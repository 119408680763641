import { TransactionFieldsFragment } from '../generated/graphql';
import create, { StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface TransactionSlice {
  newTransactions: { [accountId: string]: TransactionFieldsFragment[] };
  addNewTransaction: (transaction: TransactionFieldsFragment, accountId: string) => void;
  clearNewTransactions: () => void;
}

export type AppStore = TransactionSlice;

type Slice<T> = StateCreator<AppStore, [['zustand/immer', never], ['zustand/devtools', never]], [], T>;

const createTransactionSlice: Slice<TransactionSlice> = (set) => ({
  newTransactions: {},
  addNewTransaction: (transaction, accountId) => {
    set(
      (state) => {
        if (!(state.newTransactions[accountId] instanceof Array)) {
          state.newTransactions[accountId] = [transaction];
        } else {
          state.newTransactions[accountId].unshift(transaction);
        }
      },
      false,
      { type: 'transaction/addNewTransaction', transaction, accountId }
    );
  },
  clearNewTransactions: () => {
    set(
      (state) => {
        state.newTransactions = {};
      },
      false,
      'transaction/clearNewTransactions'
    );
  }
});

export const useTransactionStore = create<AppStore>()(
  immer(
    devtools((...a) => ({
      ...createTransactionSlice(...a)
    }))
  )
);
