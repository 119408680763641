// eslint-disable @typescript-eslint/no-unused-vars
import { CreateCategoryModal } from './CreateCategoryModal';

export * from './CreateCategoryModal';

export const Modals = {
  CreateCategory: 'createCategory',
};
export const ModalRegistry = {
  [Modals.CreateCategory]: CreateCategoryModal,
};
