import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { useColorScheme, useHotkeys, useLocalStorage } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider, showNotification } from '@mantine/notifications';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { ModalRegistry } from 'components/modals';
import { AccountsSummaryDocument, AccountsSummaryQuery, GraphCacheConfig } from 'generated/graphql';
import { FC, PropsWithChildren } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Client, dedupExchange, errorExchange, fetchExchange, Provider as UrqlProvider } from 'urql';
import Routing from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root')!;
const root = createRoot(container);

const cache = cacheExchange<GraphCacheConfig>({
  updates: {
    Mutation: {
      addAccount(result, _args, cache, _info) {
        if (!result?.addAccount?.account) {
          return;
        }
        cache.updateQuery<AccountsSummaryQuery>({ query: AccountsSummaryDocument }, (data) => {
          data?.accounts?.nodes?.push(result.addAccount.account!);
          return data;
        });
      },
      deleteAccount(result, args, cache, _info) {
        const id = result?.deleteAccount?.account?.id;
        console.log(args);
        console.log(result);
        if (!id) {
          return;
        }
        cache.updateQuery<AccountsSummaryQuery>({ query: AccountsSummaryDocument }, (data) => {
          if (!data?.accounts?.nodes) {
            return data;
          }
          data.accounts.nodes = data.accounts.nodes.filter((a) => a?.id !== id);
          return data;
        });
      }
    }
  }
});
const client = new Client({
  url: '/graphql',
  exchanges: [
    devtoolsExchange,
    dedupExchange,
    cache,
    errorExchange({
      onError: (error, _operation) => {
        console.log(error);
        showNotification({
          title: 'Request error',
          message: error.message,
          color: 'red'
        });
      }
    }),
    fetchExchange
  ]
});

const ProviderWrappper: FC<PropsWithChildren<{}>> = ({ children }) => {
  const systemColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: systemColorScheme,
    getInitialValueInEffect: true
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        theme={{
          colorScheme,
          fontFamily: 'Open Sans, sans-serif',
          headings: {
            fontFamily: 'Lato, sans-serif'
          }
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <ModalsProvider modals={ModalRegistry}>
          <NotificationsProvider limit={8}>{children}</NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

root.render(
  // <React.StrictMode>

  <UrqlProvider value={client}>
    <BrowserRouter>
      <ProviderWrappper>
        <Routing />
      </ProviderWrappper>
    </BrowserRouter>
  </UrqlProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
