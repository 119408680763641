import { Badge, Button, Stack, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import cn from 'classnames';
import { FormNumberInput, FormSelect, FormTextInput } from 'forms/formInputs';
import { AddAccountInput, useCreateAccountMutation } from 'generated/graphql';
import { FC, useId } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export interface NewAccountPageProps {
  className?: string;
}

export interface NewAccountFormData {
  name: string;
  startingAmount: number;
  currency: string | null;
}

export interface Currency {
  label: string;
  value: string;
}

export const CURRENCIES: string[] = ['EUR', 'GBP', 'USD'];

export const NewAccountPage: FC<NewAccountPageProps> = ({ className }) => {
  const form = useForm<NewAccountFormData>({
    defaultValues: {
      name: '',
      startingAmount: 0,
      currency: CURRENCIES[0],
    },
  });

  const id = useId();
  const currency = useWatch({ control: form.control, name: 'currency' });
  const navigate = useNavigate();

  const [_, createAccount] = useCreateAccountMutation();

  const onSubmit = async (data: NewAccountFormData) => {
    const input: AddAccountInput = {
      name: data.name,
      startingAmount: Number(data.startingAmount),
    };
    const result = await createAccount({ account: input });
    if (result.error) {
      return;
    }
    showNotification({
      title: 'Account created!',
      message: `Account "${data.name}" has been created.`,
      autoClose: 10000,
      color: 'green',
    });
    if (!result.data?.addAccount.account?.id) {
      return;
    }
    navigate(`/a/${result.data?.addAccount.account?.id}`);
  };

  return (
    <div className={cn(className, 'container')}>
      <Title order={2}>Add New Account</Title>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <Stack sx={{ width: 500 }}>
            <FormTextInput
              id={`${id}-name`}
              label="Name"
              name="name"
              rules={{
                required: { message: 'Name is required.', value: true },
                maxLength: {
                  message: 'Name cannot be longer than 100 characters.',
                  value: 100,
                },
              }}
            />

            <FormSelect
              id={`${id}-currency`}
              label="Currency"
              name="currency"
              rules={{ required: true }}
              data={CURRENCIES}
            />

            <FormNumberInput
              id={`${id}-startingAmount`}
              label="Starting Amount"
              name="startingAmount"
              rules={{ required: true }}
              rightSection={<Badge variant="filled">{currency}</Badge>}
              rightSectionWidth={70}
            />

            <Button type="submit">Submit</Button>
          </Stack>
        </FormProvider>
      </form>
    </div>
  );
};
