import { Button, LoadingOverlay } from '@mantine/core';
import { useModals } from '@mantine/modals';
import styles from 'account/account.module.scss';
import cn from 'classnames';
import { CreateCategoryModalProps, Modals } from 'components/modals';
import { FormDatePicker, FormNumberInput, FormSelect, FormTextInput } from 'forms/formInputs';
import {
  AddTransactionInput,
  CategorySummaryFragment,
  useAddTransactionMutation,
  useGetAllActorsQuery,
  useGetAllCategoriesQuery
} from 'generated/graphql';
import { FC, useCallback, useId, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTransactionStore } from '../../store/store';

export interface AddTransactionFormProps {
  className?: string;
  accountId: string;
  onCloseForm?: () => void;
}

interface AddTransactionFormStruct {
  dateStamp: Date;
  actor: string; // ID.
  category: string; // ID.
  description?: string;
  amount: number;
}

export const AddTransactionForm: FC<AddTransactionFormProps> = ({ className, accountId, onCloseForm }) => {
  const defaultDateStamp = useMemo(() => {
    // const date = new Date().toISOString();
    // return date.slice(0, date.indexOf('T'));
    return new Date();
  }, []); // FIXME: Not populating the input.

  const form = useForm<AddTransactionFormStruct>({
    defaultValues: {
      dateStamp: defaultDateStamp,
      description: '',
      amount: 0,
      category: '',
      actor: ''
    }
  });
  const { handleSubmit, setValue, reset } = form;
  const id = useId();
  const modals = useModals();
  const addNewTransaction = useTransactionStore((state) => state.addNewTransaction);

  const [createdActor, setCreatedActor] = useState<string | null>(null);
  const [createdCategory, setCreatedCategory] = useState<Pick<
    CategorySummaryFragment,
    'name' | 'parentCategory'
  > | null>(null);

  const [{ data: categories, fetching: catFetching, error: catError }] = useGetAllCategoriesQuery();
  const [{ data: actors, fetching: actFetching, error: actError }] = useGetAllActorsQuery();
  const [{ fetching: addTransactionFetching }, addTransaction] = useAddTransactionMutation();

  const actorData = useMemo(() => {
    if (!actors?.actors) {
      return [];
    }
    const res = actors.actors.map((actor) => ({
      value: actor.id,
      label: actor.name
    }));
    if (createdActor) {
      res.push({
        value: `NEW:${createdActor}`,
        label: createdActor
      });
    }
    return res;
  }, [actors, createdActor]);

  const categoryData = useMemo(() => {
    if (!categories?.categories) {
      return [];
    }
    const res = categories.categories.map((cat) => ({
      value: cat.id,
      label: cat.name,
      group: cat.parentCategory
    }));
    if (createdCategory) {
      res.push({
        value: `NEW:${createdCategory.name}`,
        label: createdCategory.name,
        group: createdCategory.parentCategory
      });
    }
    return res;
  }, [categories, createdCategory]);

  const onSubmit = useCallback(
    async (data: AddTransactionFormStruct) => {
      const input: AddTransactionInput = {
        amount: data.amount,
        accountId: accountId,
        dateStamp: data.dateStamp.toISOString().slice(0, data.dateStamp.toISOString().indexOf('T')),
        description: data.description
      };
      if (data.actor.startsWith('NEW:')) {
        input.actorName = createdActor;
      } else {
        input.actorId = data.actor;
      }
      if (data.category.startsWith('NEW:')) {
        if (!createdCategory) {
          console.error('Chosen "NEW:" category, but createdCategory is falsy.');
          return;
        }
        input.categoryName = createdCategory.name;
        input.categoryParent = createdCategory.parentCategory;
      } else {
        input.categoryId = data.category;
      }

      const result = await addTransaction({ transaction: input });
      if (result.error) {
        return;
      }

      reset();
      if (!result.data?.addTransaction?.transaction) {
        return;
      }
      addNewTransaction(result.data?.addTransaction.transaction, accountId);
      // Don't close form, to make it easier to add multiple transactions.
    },
    [accountId, createdCategory, createdActor, addTransaction, reset, addNewTransaction]
  );

  return (
    <div className={cn(className, 'container')}>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles['add-transaction-form']}
          style={{ position: 'relative' }} // For the LoadingOverlay.
        >
          <LoadingOverlay visible={addTransactionFetching} />
          <FormDatePicker
            label="Date"
            id={`${id}-dateStamp`}
            name="dateStamp"
            rules={{
              required: true
            }}
            allowFreeInput
            withinPortal
          />

          <FormSelect
            label="Actor"
            data={actorData}
            id={`${id}-actor`}
            name="actor"
            rules={{ required: true }}
            disabled={actFetching || !!actError}
            searchable
            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              setCreatedActor(query);
              setValue('actor', `NEW:${query}`);
              return {
                value: `NEW:${query}`,
                label: query
              };
            }}
            onChange={(v) => {
              const catId = actors?.actors.find((actor) => actor.id === v)?.defaultCategoryId;
              if (!catId) {
                return;
              }
              setValue('category', catId);
            }}
            withinPortal={true}
          />

          <FormSelect
            label="Category"
            data={categoryData}
            id={`${id}-category`}
            name="category"
            rules={{ required: true }}
            disabled={catFetching || !!catError}
            searchable
            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              modals.openContextModal<CreateCategoryModalProps>(Modals.CreateCategory, {
                title: 'Create new Category',
                innerProps: {
                  categoryName: query,
                  onSubmit: (data) => {
                    setCreatedCategory({
                      name: data.categoryName,
                      parentCategory: data.categoryParent
                    });
                    setValue('category', `NEW:${data.categoryName}`);
                  }
                }
              });
              return '';
            }}
            withinPortal={true}
          />

          <FormTextInput id={`${id}-description`} name="description" rules={{ maxLength: 255 }} label="Description" />

          <FormNumberInput name="amount" rules={{ required: true }} id={`${id}-amount`} label="Amount" />
          <div className={styles['action-container']}>
            <Button
              onClick={() => {
                typeof onCloseForm === 'function' && onCloseForm();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Add Transaction</Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
