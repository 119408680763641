import { Button, Collapse, Group, Paper, ScrollArea, Text, Title } from '@mantine/core';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { useAccountDetailsQuery, useGenerateFakeDataMutation } from '../generated/graphql';
import styles from './account.module.scss';
import { AddTransactionForm } from './transaction/AddTransactionForm';
import { TransactionTable } from './transaction/TransactionTable';

export interface AccountPageProps {
  className?: string;
}

export const AccountPage: FC<AccountPageProps> = ({ className }) => {
  const { accId } = useParams();

  const [result] = useAccountDetailsQuery({
    variables: { accId: accId ?? '' },
    pause: accId === undefined
  });
  const [_, generateData] = useGenerateFakeDataMutation();

  const [newTransactionIsOpen, setNewTransactionIsOpen] = useState(false);

  const { data, fetching, error } = result;

  if (!accId) {
    return <p></p>;
  }

  if (fetching) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return <div>Error.. {error?.message}</div>;
  }

  if (!data.account) {
    return <div>Account not found.</div>;
  }
  const account = data.account;

  return (
    <div className={classNames(styles['page-container'], className)}>
      <div className={styles.header}>
        <Title order={2}>{account.name}</Title>
        <Text size="md">Current: {account.currentAmount} €</Text>
      </div>
      <div className={styles.content}>
        <Group spacing="xs" pt={4} pb={12}>
          <Button size="sm" onClick={() => setNewTransactionIsOpen(true)} leftIcon={<Plus />}>
            Add Transaction
          </Button>
          <Button size="sm" onClick={() => generateData({ accId: account.id, count: 20 })}>
            Generate
          </Button>
        </Group>
        <Paper shadow="sm" className="flex-grow" component={ScrollArea}>
          {/* <ScrollArea className="flex-grow"> */}
          {/*<TransactionTable accountId={accId} />*/}
          {/* </ScrollArea> */}
          <TransactionTable accountId={accId} />
        </Paper>
        <Collapse in={newTransactionIsOpen} pt={10}>
          <Paper shadow="sm">
            <AddTransactionForm
              accountId={accId}
              onCloseForm={() => {
                setNewTransactionIsOpen(false);
              }}
            />
          </Paper>
        </Collapse>
      </div>
      <Paper className={styles.details} shadow="sm"></Paper>
    </div>
  );
};
